import Link from 'next/link';
import * as React from 'react';

type Props = React.HTMLAttributes<HTMLAnchorElement> & {
  isExternal?: boolean;
  link: string;
  target?: string;
};
const AjaxLink: React.FC<Props> = ({
  isExternal,
  children,
  link,
  target,
  ...restProps
}) => (
  <Link
    href={link}
    target={target || undefined}
    rel={isExternal ? 'noopener noreferrer' : undefined}
    {...restProps}
  >
    {children}
  </Link>
);

export default AjaxLink;
