import { useRouter } from 'next/router';
import * as React from 'react';
import type { LinkModel } from '~source/core/models/components/atoms/link';
import type { MenuItems } from '~source/core/models/components/organisms/navigation';
import CartIcon from '~source/ui/components/atoms/cart-icon/cart-icon';
import AjaxLink from '~source/ui/components/atoms/Link/link';
import AccountInfo from '~source/ui/components/organisms/navigation-components/navigation/account-info';
import NavigationItem from '~source/ui/components/organisms/navigation-components/navigation-item/navigation-item';
import { useCart } from '~source/ui/hooks/cart/useCart/useCart';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './navigation-items.module.scss';

type Props = {
  items: MenuItems[];
  extraLinks?: LinkModel[];
  activeItemId: string | null;
  dropdownHeight: number | null;
  toggleDropdown: (id: string) => void;
  updateDropdownHeight: (height: number) => void;
};

const NavigationItems: React.FC<Props> = ({
  items,
  extraLinks,
  activeItemId,
  dropdownHeight,
  toggleDropdown,
  updateDropdownHeight,
}) => {
  const { displayCartAmount } = useCart();
  const router = useRouter();
  const t = useTranslate();
  const isCart = router.pathname === '/cart';

  return (
    <ul className={$.wrapper}>
      {!isCart &&
        items.map(({ label, links, highlight }) => (
          <NavigationItem
            key={label}
            id={`navigation-items-${label}`}
            label={label}
            activeItemId={activeItemId}
            items={links}
            dropdownHeight={dropdownHeight}
            toggleDropdown={toggleDropdown}
            updateDropdownHeight={updateDropdownHeight}
            highlight={highlight}
          />
        ))}

      {isCart && (
        <li>
          <AjaxLink link="/" className={cx($.item, $.itemExternal)}>
            {t('FANSHOP_LINK_NAME')}
          </AjaxLink>
        </li>
      )}

      {extraLinks?.map(({ url, target, name }) => (
        <li key={name}>
          <AjaxLink
            link={url}
            target={target}
            isExternal={target === '_blank'}
            className={cx($.item, $.itemExternal)}
          >
            {name}
          </AjaxLink>
        </li>
      ))}

      <li className={cx('is-hidden--mobile', 'is-hidden--tablet')}>
        <CartIcon amount={displayCartAmount || 0} />
      </li>

      <AccountInfo
        activeNavItemId={activeItemId}
        dropdownHeight={dropdownHeight}
        toggleDropdown={toggleDropdown}
        updateDropdownHeight={updateDropdownHeight}
      />
    </ul>
  );
};

export default NavigationItems;
