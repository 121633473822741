import * as React from 'react';

import type { FeatureFlag } from '~source/core/models/response/feature-flags-response';
import getFeatureFlags from '~source/core/services/feature-flags/feature-flags';

export type FeatureFlagsContextType = {
  featureFlags: Partial<Record<FeatureFlag, boolean>> | null;
};

const FeatureFlagsContext = React.createContext<FeatureFlagsContextType | null>(
  null,
);

const DEFAULT_FEATURE_FLAGS: Record<FeatureFlag, boolean> = {
  checkout_form_labels: false,
  checkout_price_on_mobile: false,
};

export function useFeatureFlags(): FeatureFlagsContextType {
  const context = React.useContext(FeatureFlagsContext);
  if (!context) throw new Error('[useFeatureFlags] No context found');
  return context;
}

export const FeatureFlagsProvider = ({ children }: React.PropsWithChildren) => {
  const [featureFlags, setFeatureFlags] = React.useState<Partial<
    Record<FeatureFlag, boolean>
  > | null>(null);

  // Disabled for now, as we don't have active feature flags currently
  React.useEffect(() => {
    // Configure a timeout, so we can fallback to default feature flags when it's reached
    const signal = AbortSignal.timeout(4000);

    getFeatureFlags({ signal })
      .then(({ featureFlags: retrievedFeatureFlags }) => {
        const updatedFeatureFlags = {
          ...DEFAULT_FEATURE_FLAGS,
          ...retrievedFeatureFlags,
        };

        // @ts-ignore dataLayer is defined
        window.dataLayer.push({
          event: 'featureFlags',
          featureFlags: updatedFeatureFlags,
        });

        setFeatureFlags(updatedFeatureFlags);
      })
      .catch(() => {
        setFeatureFlags(DEFAULT_FEATURE_FLAGS);
      });
  }, []);

  const contextValue = React.useMemo<FeatureFlagsContextType>(
    () => ({
      featureFlags,
    }),
    [featureFlags],
  );

  return (
    <FeatureFlagsContext.Provider value={contextValue}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
