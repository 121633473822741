import Image from 'next/image';
import * as React from 'react';
import type { Icon } from '~source/core/models/components/atoms/icon';
import type { LinkModel } from '~source/core/models/components/atoms/link';
import type { MenuItems } from '~source/core/models/components/organisms/navigation';
import LanguageSelector from '~source/ui/components/atoms/language-selector/language-selector';
import AjaxLink from '~source/ui/components/atoms/Link/link';
import NavigationItems from '~source/ui/components/organisms/navigation-components/navigation-items/navigation-items';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './mobile-menu.module.scss';

type Props = {
  isOpen: boolean;
  menuItems: MenuItems[];
  extraLinks: LinkModel[] | undefined;
  socials: Icon[] | null;
  localeRedirectToHome?: boolean;
  activeNavItemId: string | null;
  dropdownHeight: number | null;
  onToggle: () => void;
  toggleDropdown: (id: string) => void;
  mobileMenuRef: React.MutableRefObject<HTMLDivElement | null>;
  mobileMenuButtonRef: React.MutableRefObject<HTMLButtonElement | null>;
  updateDropdownHeight: (height: number) => void;
};

const MobileMenu: React.FC<Props> = ({
  isOpen,
  menuItems,
  extraLinks,
  socials,
  localeRedirectToHome,
  activeNavItemId,
  dropdownHeight,
  onToggle,
  toggleDropdown,
  mobileMenuRef,
  mobileMenuButtonRef,
  updateDropdownHeight,
}) => {
  const t = useTranslate();

  return (
    <>
      <button
        type="button"
        aria-label={t(isOpen ? 'MENU_CLOSE' : 'MENU_OPEN')}
        aria-expanded={isOpen}
        aria-controls="mobile-menu"
        className={$.button}
        onClick={onToggle}
        ref={mobileMenuButtonRef}
      >
        <Image
          src={`/shop/images/icons/ic-${isOpen ? 'close' : 'hamburger'}.svg`}
          alt=""
          width="24"
          height="24"
        />
      </button>

      <div className={cx($.menu, isOpen && $.menuOpen)} ref={mobileMenuRef}>
        <NavigationItems
          items={menuItems}
          extraLinks={extraLinks}
          activeItemId={activeNavItemId}
          toggleDropdown={toggleDropdown}
          dropdownHeight={dropdownHeight}
          updateDropdownHeight={updateDropdownHeight}
        />

        <div className={$.bottom}>
          {socials && (
            <ul className={$.socials}>
              {socials.map(({ link, image }) => (
                <li key={link.id}>
                  <AjaxLink
                    link={link.url}
                    target={link.target}
                    isExternal={link.target === '_blank'}
                    aria-label={link.name}
                    title={link.name}
                  >
                    <Image
                      src={image.url}
                      alt=""
                      width="24"
                      height="24"
                      loading="lazy"
                    />
                  </AjaxLink>
                </li>
              ))}
            </ul>
          )}

          <LanguageSelector
            id="language-selector-mobile"
            localeRedirectToHome={localeRedirectToHome}
          />
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
