import Image from 'next/image';
import * as React from 'react';
import type { Image as ImageModel } from '~source/core/models/components/atoms/image';
import AjaxLink from '~source/ui/components/atoms/Link/link';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './navigation-logo.module.scss';

type Props = {
  logo: {
    desktop: ImageModel | null;
    image: ImageModel | null;
    mobile: ImageModel | null;
  };
};

const NavigationLogo: React.FC<Props> = ({ logo }) => {
  const { desktop, image, mobile } = logo;
  const t = useTranslate();

  return (
    <AjaxLink
      link="/"
      aria-label={t('A11Y_GO_TO_HOMEPAGE')}
      className={$.wrapper}
    >
      {image && (
        <Image
          src={image.url}
          alt={image.alt}
          className={cx(
            $.logo,
            $.logoIcon,
            'is-hidden--mobile',
            'is-hidden--tablet',
          )}
          width="49"
          height="64"
          priority
        />
      )}

      {desktop && (
        <Image
          src={desktop.url}
          alt={desktop.alt}
          className={cx($.logo, $.logoText, $.logoDesktop)}
          width="177"
          height="24"
          priority
        />
      )}

      {mobile && (
        <Image
          src={mobile.url}
          alt={mobile.alt}
          className={cx($.logo, $.logoText, $.logoMobile)}
          width="82"
          height="24"
          priority
        />
      )}
    </AjaxLink>
  );
};

export default NavigationLogo;
