import type { DYLoginData } from '~source/core/models/payload/dy-login-data';
import type { DYProductData } from '~source/core/models/payload/dy-product-data';
import type { DYSyncCartData } from '~source/core/models/payload/dy-sync-data';
import createDYUpdateCartPayload from '~source/ui/utils/dynamic-yield/create-dy-update-cart-payload';
import createDYLoginPayload from './create-dy-login-payload';
import type { DYPurchaseData } from './create-dy-purchase-payload';
import createDYPurchasePayload from './create-dy-purchase-payload';
import createDYSyncCartPayload from './create-dy-sync-cart-payload';

type DYEventType =
  | 'Purchase'
  | 'Add to Cart'
  | 'Remove from Cart'
  | 'Sync cart'
  | 'Login'
  | 'Signup'
  | 'Clicked on Log in';

type DYData = DYPurchaseData | DYProductData | DYSyncCartData | DYLoginData;

export function getPayload(type: DYEventType, data?: DYData) {
  switch (type) {
    case 'Remove from Cart':
      return createDYUpdateCartPayload(data as DYProductData, true);
    case 'Add to Cart':
      return createDYUpdateCartPayload(data as DYProductData);
    case 'Purchase':
      return createDYPurchasePayload(data as DYPurchaseData);
    case 'Sync cart':
      return createDYSyncCartPayload(data as DYSyncCartData);
    case 'Login':
    case 'Signup':
      return createDYLoginPayload(data as DYLoginData);
    case 'Clicked on Log in':
      return { name: 'Clicked on Log in' };
    default:
      console.error(`Type of ${type} is not a valid DYEventType`);
      return null;
  }
}

export default function sendDYEvent(type: DYEventType, data?: DYData) {
  // @ts-ignore DY is a third party script
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!window?.DY?.API) {
    console.warn(
      `[sendDYEvent] - Couldn't send DY event because DY.API does not exist`,
    );
    return;
  }
  const payload = getPayload(type, data);
  if (!payload) {
    console.warn('[sendDYEvent] - No payload provided');
    return;
  }
  // @ts-ignore DY is a third party script
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window?.DY?.API('event', payload);
}
