import * as React from 'react';
import type { NavigationHighlight as NavigationHighlightType } from '~source/core/models/components/organisms/navigation';
import AjaxLink from '~source/ui/components/atoms/Link/link';
import AccountIcon from '~source/ui/components/organisms/navigation-components/navigation/account-icon';
import NavigationHighlight from '~source/ui/components/organisms/navigation-components/navigation-highlight/navigation-highlight';
import useBreakpoints from '~source/ui/hooks/ui/useBreakpoints/useBreakpoints';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './navigation-item.module.scss';

type Props = {
  id: string;
  label: string;
  activeItemId: string | null;
  dropdownHeight: number | null;
  items: {
    id: string;
    name: string;
    url?: string;
    target?: string;
    onClick?: () => void;
  }[];
  highlight?: NavigationHighlightType;
  toggleDropdown: (id: string) => void;
  updateDropdownHeight: (height: number) => void;
  isAccountIcon?: boolean;
};

const NavigationItem: React.FC<Props> = ({
  id,
  label,
  activeItemId,
  dropdownHeight,
  items,
  highlight,
  toggleDropdown,
  updateDropdownHeight,
  isAccountIcon = false,
}) => {
  const [currentFocusIndex, setCurrentFocusIndex] = React.useState(-1);
  const submenuRef = React.useRef<HTMLUListElement | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  const { isLaptop } = useBreakpoints();
  const itemID = React.useId();
  const isOpen = id === activeItemId;
  const dropdownStyles = {
    '--dropdown-height': `${dropdownHeight}px`,
  } as React.CSSProperties;

  const goToItem = (index: number) => {
    if (!submenuRef.current) return;

    setCurrentFocusIndex(index);

    const element: HTMLElement | null = submenuRef.current.querySelector(
      `[data-item="${items[index].name}"]`,
    );

    element?.focus();
  };

  const goToPreviousItem = () => {
    if (currentFocusIndex === 0) {
      setCurrentFocusIndex(-1);
      buttonRef.current?.focus();
      return;
    }

    goToItem(currentFocusIndex - 1);
  };

  const goToNextItem = () => {
    if (currentFocusIndex === items.length - 1) return;

    goToItem(currentFocusIndex + 1);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'Escape':
        toggleDropdown(id);
        buttonRef.current?.focus();
        break;
      case 'ArrowUp':
        e.preventDefault();
        goToPreviousItem();
        break;
      case 'ArrowDown':
        e.preventDefault();
        goToNextItem();
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    if (!submenuRef.current || !isLaptop) return;

    updateDropdownHeight(submenuRef.current.scrollHeight);
  }, [isLaptop, updateDropdownHeight]);

  return (
    <li className={$.wrapper}>
      <button
        type="button"
        className={cx($.button, isOpen && $.buttonActive)}
        onClick={() => toggleDropdown(id)}
        aria-expanded={isOpen}
        aria-controls={itemID}
        aria-haspopup="true"
        ref={buttonRef}
        onKeyDown={handleKeyDown}
      >
        {isAccountIcon && <AccountIcon className={$.accountIcon} />}
        {label}
      </button>

      <div
        className={cx($.dropdown, isOpen && $.dropdownOpen)}
        style={dropdownStyles}
      />

      {highlight && <NavigationHighlight label={label} {...highlight} />}

      <ul
        className={cx($.submenu, !isOpen && $.submenuHidden)}
        ref={submenuRef}
      >
        {items.map((item) => (
          <li
            key={item.id}
            className={cx($.submenuItem, isOpen && $.submenuItemActive)}
          >
            {item.url ? (
              <AjaxLink
                link={item.url.toLowerCase()}
                target={item.target}
                isExternal={item.target === '_blank'}
                className={$.link}
                data-item={item.name}
                onKeyDown={handleKeyDown}
                tabIndex={isOpen ? 0 : -1}
              >
                {item.name}
              </AjaxLink>
            ) : (
              <button
                type="button"
                className={$.link}
                onClick={() => item.onClick?.()}
                data-item={item.name}
                onKeyDown={handleKeyDown}
                tabIndex={isOpen ? 0 : -1}
              >
                {item.name}
              </button>
            )}
          </li>
        ))}
      </ul>
    </li>
  );
};

export default NavigationItem;
