import getConfig from 'next/config';
import type { LocaleLanguageType } from '~source/core/models/unions/locale';

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
const publicConfig = getConfig?.()?.publicRuntimeConfig ?? {};

function validateValue<T>(value: T, name: string): NonNullable<T> {
  if (value != null) {
    return value as NonNullable<T>;
  }
  throw new Error(`Missing environment variable "${name}".`);
}

// TODO(Refactor): Separate constants in different files
/* eslint-disable prefer-destructuring */
export const BREAK_PHONE_VALUE = 375;
export const BREAK_SMALL_TABLET_VALUE = 768;
export const BREAK_TABLET_VALUE = 900;
export const BREAK_TABLET_LS_VALUE = 1024;
export const BREAK_LAPTOP_VALUE = 1195;
export const BREAK_LAPTOP_BIG_VALUE = 1440;
export const GUTTER_PHONE_VALUE = 24;
export const GUTTER_TABLET_VALUE = 80;
export const GUTTER_DESKTOP_VALUE = 100;
export const EVA_BACKEND_URL = validateValue(
  process.env.NEXT_PUBLIC_EVA_BACKEND_URL,
  'NEXT_PUBLIC_EVA_BACKEND_URL',
);
export const EVA_BACKEND_KEY = validateValue(
  process.env.NEXT_PUBLIC_EVA_KEY,
  'NEXT_PUBLIC_EVA_KEY',
);
export const ORGANIZATION_UNIT_ID = validateValue(
  process.env.NEXT_PUBLIC_ORGANIZATION_UNIT_ID,
  'NEXT_PUBLIC_ORGANIZATION_UNIT_ID',
);
export const AJAX_SSO_CLIENT_PROVIDER_ID = validateValue(
  process.env.NEXT_PUBLIC_AJAX_SSO_CLIENT_PROVIDER_ID,
  'NEXT_PUBLIC_AJAX_SSO_CLIENT_PROVIDER_ID',
);
export const AJAX_SSO_CLIENT_NATIVE_APP_PROVIDER_ID = validateValue(
  process.env.NEXT_PUBLIC_AJAX_SSO_CLIENT_NATIVE_APP_PROVIDER_ID,
  'NEXT_PUBLIC_AJAX_SSO_CLIENT_NATIVE_APP_PROVIDER_ID',
);
export const AJAX_SSO_CLIENT_ID = process.env
  .NEXT_PUBLIC_AJAX_SSO_CLIENT_ID as string;
export const AJAX_SSO_REDIRECT_URI = process.env
  .NEXT_PUBLIC_AJAX_SSO_REDIRECT_URI as string;
export const API_URL = validateValue(
  process.env.NEXT_PUBLIC_API_URL,
  'NEXT_PUBLIC_API_URL',
);
export const AJAX_HREF = validateValue(
  process.env.NEXT_PUBLIC_HREF,
  'NEXT_PUBLIC_HREF',
);
export const AJAX_HREF_EN = validateValue(
  process.env.NEXT_PUBLIC_HREF_EN,
  'NEXT_PUBLIC_HREF_EN',
);

export const AJAX_COOKIE_DOMAIN = validateValue(
  process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
  'NEXT_PUBLIC_COOKIE_DOMAIN',
);

export const ADYEN_SDK_CONTEXT = validateValue(
  process.env.NEXT_PUBLIC_ADYEN_SDK_CONTEXT,
  'NEXT_PUBLIC_ADYEN_SDK_CONTEXT',
);
export const ADYEN_SDK_CLIENT_KEY = validateValue(
  process.env.NEXT_PUBLIC_ADYEN_SDK_CLIENT_KEY,
  'NEXT_PUBLIC_ADYEN_SDK_CLIENT_KEY',
);
export const ADYEN_MERCHANT_NAME = 'AFC Ajax NV';
export const ADYEN_GATEWAY_MERCHANT_ID = 'AFCAjaxNV_Merchandise';
export const ADYEN_MERCHANT_ID = 'BCR2DN4TTDXNVMSO';

export const PRODUCT_TYPE_SHIPPING = 5;
export const DEFAULT_LOCALE: LocaleLanguageType = 'nl-NL';
export const GIFTWRAPPING_DISABLED =
  publicConfig.giftWrappingDisabled as boolean;
export const AVAILABILITY_MESSAGE_DISABLED =
  publicConfig.availabilityMessageDisabled as boolean;
export const DYNAMIC_YIELD_ID = validateValue(
  process.env.NEXT_PUBLIC_DYNAMIC_YIELD_ID,
  'NEXT_PUBLIC_DYNAMIC_YIELD_ID',
);
export const CUSTOM_GATEWAY_ACCOUNT_NAME =
  process.env.NEXT_PUBLIC_CUSTOM_GATEWAY_ACCOUNT_NAME;
export const CUSTOM_GATEWAY_HTML = process.env.NEXT_PUBLIC_CUSTOM_GATEWAY_HTML;
// Do not change
export const COUNTRIES_WITHOUT_ZIPCODE: string[] = [
  'Cyprus',
  'United Arab Emirates',
];
export const ZIPCODE_FALLBACK_VALUE = 'NO_ZIPCODE';
export const ID_EVENING_DELIVERY = validateValue(
  process.env.NEXT_PUBLIC_ID_EVENING_DELIVERY,
  'NEXT_PUBLIC_ID_EVENING_DELIVERY',
);
export const ID_DHL_SERVICEPOINT = validateValue(
  process.env.NEXT_PUBLIC_ID_DHL_SERVICEPOINT,
  'NEXT_PUBLIC_ID_DHL_SERVICEPOINT',
);
export const ID_DHL_SERVICENAME = validateValue(
  process.env.NEXT_PUBLIC_ID_DHL_SERVICENAME,
  'NEXT_PUBLIC_ID_DHL_SERVICENAME',
);
export const ID_DHL_SHIPPING_METHOD = validateValue(
  process.env.NEXT_PUBLIC_ID_DHL_SHIPPING_METHOD,
  'NEXT_PUBLIC_ID_DHL_SHIPPING_METHOD',
);
export const ID_DELIVERY_INFO = validateValue(
  process.env.NEXT_PUBLIC_ID_DELIVERY_INFO,
  'NEXT_PUBLIC_ID_DELIVERY_INFO',
);
export const ID_MARKETING_OUTINGS = validateValue(
  process.env.NEXT_PUBLIC_ID_MARKETING_OUTINGS,
  'NEXT_PUBLIC_ID_MARKETING_OUTINGS',
);
export const CHAT_URL =
  'https://secure.livechatinc.com/licence/8926289/v2/open_chat.cgi?groups=0';

const BASIC_REQUIRED_FIELDS = [
  'currency_id',
  'display_price',
  'display_value',
  'product_id',
  'primary_image',
  'slug',
  'product_statuses',
  'collectie',
  'hoofd_categorie',
  'artikel_categorie',
  'leeftijd_groep',
  'ontwerp_zelf',
];

export const SEARCH_PRODUCTS_REQUIRED_FIELDS = [
  ...BASIC_REQUIRED_FIELDS,
  'customisable_product',
  'subtitel',
  'marketing_titel',
  'prio_score2',
  '*',
];

export const GET_PRODUCTS_REQUIRED_FIELDS = [
  ...BASIC_REQUIRED_FIELDS,
  'parent_id',
];

const BASIC_REQUIRED_PROPERTIES = [
  'size',
  'marketing_titel',
  'primary_image',
  'categorie',
  'product_requirements',
  'item',
  'printing',
  'backend_id',
  'hoofd_categorie',
];

export const SHOPPING_CART_REQUIRED_PROPERTIES = [
  ...BASIC_REQUIRED_PROPERTIES,
  'cta_pdp_text',
  'cta_pdp_text_prs',
  'product_statuses',
  'custom_gateway',
  'collectie',
  'artikel_categorie',
  'leeftijd_groep',
  'ontwerp_zelf',
];

export const ORDER_SUCCESS_PAGE_REQUIRED_PROPERTIES = [
  ...BASIC_REQUIRED_PROPERTIES,
  'display_value',
  'original_price',
  'max_print_length',
  'shirt_type',
  'config_contains_no_name',
  'product_statuses',
  'collectie',
  'artikel_categorie',
  'leeftijd_groep',
  'ontwerp_zelf',
];

export const ORDER_DETAIL_REQUIRED_PROPERTIES = [
  ...BASIC_REQUIRED_PROPERTIES,
  'display_value',
  'original_price',
  'max_print_length',
  'shirt_type',
  'config_contains_no_name',
];

export const SHIRT_TYPES = {
  home: '2324_home',
  away: '2324_away',
  third: '2324_3rd',
  keeper: '2324_keeper',
  homeNew: '2425_home',
  awayNew: '2425_away',
  thirdNew: '2425_3rd',
  keeperNew: '2425_keeper',
  homeWomen: '2425_home_vrouwen',
} as const;

export const DYNAMIC_PREVIEW_ENABLED_SHIRT_TYPES = [
  SHIRT_TYPES.homeNew,
  SHIRT_TYPES.awayNew,
  SHIRT_TYPES.thirdNew,
  SHIRT_TYPES.keeperNew,
] satisfies (typeof SHIRT_TYPES)[keyof typeof SHIRT_TYPES][];

export const REQUIRED_FOR_TYPE = 7; // Type that includes placeOrder, Payment and Ship requirements

export const CUSTOM_ERROR = {
  TITLE: "Don't worry about a thing",
  TEXT: 'Sorry, we hebben deze pagina niet gevonden.',
  SECONDARY_TEXT:
    'Waarschijnlijk bestaat deze pagina niet of niet meer. Misschien dat er een tikfoutje in de URL is gemaakt.',
};

export const AJAX_FANSHOP_ID_ARENA = Number(
  process.env.NEXT_PUBLIC_ACTIVE_AJAX_FANSHOP_ID_ARENA,
);
export const AJAX_FANSHOP_ID_KALVERSTRAAT = Number(
  process.env.NEXT_PUBLIC_ACTIVE_AJAX_FANSHOP_ID_KALVERSTRAAT,
);
export const AJAX_FANSHOP_ID_BATAVIA = Number(
  process.env.NEXT_PUBLIC_ACTIVE_AJAX_FANSHOP_ID_BATAVIA,
);

export const ACTIVE_AJAX_FANSHOP_IDS = [
  AJAX_FANSHOP_ID_ARENA,
  AJAX_FANSHOP_ID_KALVERSTRAAT,
  AJAX_FANSHOP_ID_BATAVIA,
];

export const TSD_API_KEY = validateValue(
  process.env.NEXT_PUBLIC_TSD_API_KEY,
  'NEXT_PUBLIC_TSD_API_KEY',
);

export const PAYMENT_TIMEOUT = 5 * 60 * 1000; // 5 minutes in milliseconds

export const PAYMENT_METHODS = [
  {
    name: 'iDEAL',
    imageSrc: '/shop/images/icons/paymentMethods/iDEAL.svg',
  },
  {
    name: 'Mastercard',
    imageSrc: '/shop/images/icons/paymentMethods/Mastercard.svg',
  },
  {
    name: 'Visa',
    imageSrc: '/shop/images/icons/paymentMethods/Visa.svg',
  },
  {
    name: 'Apple Pay',
    imageSrc: '/shop/images/icons/paymentMethods/Apple_Pay.svg',
  },
  {
    name: 'Klarna',
    imageSrc: '/shop/images/icons/paymentMethods/Klarna.svg',
  },
] as const;
