import type {
  CartLine,
  CartLineWithBundleProducts,
} from '~source/core/models/components/atoms/cart-line';
import type { ShoppingCart } from '~source/core/models/components/templates/shopping-cart';
import type { DynamicYieldCartItem } from '~source/core/models/payload/dy-cart-item';
import createUniqueDYProductsList from '~source/ui/utils/dynamic-yield/create-unique-dy-products-list';

function transformCartLineToDYFormat(line: CartLine): DynamicYieldCartItem {
  const { backendId, quantityOpen, price, size } = line;
  const itemPrice = price.sale || price.original || 0;
  return {
    productId: backendId || '',
    quantity: quantityOpen,
    itemPrice: +(itemPrice / quantityOpen).toFixed(2),
    size: size || null,
  };
}

export default function transformCartToDYFormat(shoppingCart: ShoppingCart) {
  const stockProducts = shoppingCart.lines.reduce(
    (lines: CartLineWithBundleProducts[], line: CartLineWithBundleProducts) => {
      if (!line.bundleProducts) return [...lines, line];
      const mainStockProduct = line.bundleProducts.find(
        (bundleProduct) => bundleProduct.size,
      );
      if (!mainStockProduct) return lines;

      return [
        ...lines,
        {
          ...mainStockProduct,
          quantityOpen: line.quantityOpen,
          price: line.price,
        },
      ];
    },
    [],
  );
  const dynamicYieldProducts = stockProducts.map((line) =>
    transformCartLineToDYFormat(line),
  );
  return createUniqueDYProductsList(dynamicYieldProducts);
}
