import * as React from 'react';
import NavigationItem from '~source/ui/components/organisms/navigation-components/navigation-item/navigation-item';
import { useAccount } from '~source/ui/hooks/auth/useAccount/useAccount';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import getAccountDisplayName from '~source/ui/utils/getters/get-account-display-name';

type Props = {
  activeNavItemId: string | null;
  dropdownHeight: number | null;
  toggleDropdown: (id: string) => void;
  updateDropdownHeight: (height: number) => void;
};

const AccountInfo: React.FC<Props> = ({
  activeNavItemId,
  dropdownHeight,
  toggleDropdown,
  updateDropdownHeight,
}) => {
  const [isMounted, setIsMounted] = React.useState(false);
  const { account, signIn, signOut } = useAccount();
  const t = useTranslate();

  const { label, items } = React.useMemo(() => {
    if (account && account.type !== 'ajax-guest-account')
      return {
        label: getAccountDisplayName(account),
        items: [
          {
            id: 'logout',
            name: t('NAVIGATION_LABEL_LOGOUT'),
            onClick: signOut,
          },
        ],
      };

    return {
      label: t('NAVIGATION_LABEL_PROFILE'),
      items: [
        {
          id: 'login',
          name: t('NAVIGATION_LABEL_LOGIN_REGISTER'),
          onClick: signIn,
        },
      ],
    };
  }, [account, signIn, signOut, t]);

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) return null;

  return (
    <NavigationItem
      id="navigation-items-profile"
      label={label}
      activeItemId={activeNavItemId}
      items={items}
      toggleDropdown={toggleDropdown}
      dropdownHeight={dropdownHeight}
      updateDropdownHeight={updateDropdownHeight}
      isAccountIcon
    />
  );
};

export default AccountInfo;
