import cx from 'classnames';
import Image from 'next/image';
import * as React from 'react';
import type { Icon } from '~source/core/models/components/atoms/icon';
import type {
  Category,
  FooterLinks,
} from '~source/core/models/components/molecules/footer';
import Button from '~source/ui/components/atoms/buttons/button/button';
import AjaxLink from '~source/ui/components/atoms/Link/link';
import Container from '~source/ui/components/containers/container/container';
import GridItem from '~source/ui/components/containers/grid-item/grid-item';
import Grid from '~source/ui/components/templates/grid/grid';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import $ from './footer.module.scss';

function IconList({ partnersSponsors }: { partnersSponsors: Icon[] }) {
  return (
    <ul className={$.iconList}>
      {partnersSponsors.map((partner) => (
        <li className={$.iconListItem} key={partner.id}>
          <AjaxLink
            link={partner.link.url}
            title={partner.link.name}
            aria-label={partner.link.name}
            target={partner.link.target}
            isExternal={partner.link.target === '_blank'}
            className={$.link}
          >
            <Image
              alt={partner.image.alt}
              src={partner.image.url}
              width="58"
              height="24"
              loading="lazy"
              className={$.brand}
            />
          </AjaxLink>
        </li>
      ))}
    </ul>
  );
}

function FooterList({ title, links }: Category) {
  return (
    <section className={$.section}>
      <h3 className={$.title}>{title}</h3>
      <ul className={$.list}>
        {links.map((link) => (
          <li className={$.listItem} key={link.id}>
            <AjaxLink
              link={link.url}
              target={link.target}
              className={$.link}
              isExternal={link.target === '_blank'}
            >
              {link.name}
            </AjaxLink>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default function Footer({ footerLinks }: { footerLinks: FooterLinks }) {
  const {
    footerImage,
    categories,
    newsletter,
    socials,
    partnersSponsors,
    copyright,
  } = footerLinks;
  const t = useTranslate();
  if (!categories.length) return null;
  const firstCategories = categories.slice(0, 2);
  const secondCategories = categories.slice(2, 4);
  return (
    <footer className={$.wrap} role="contentinfo">
      <Container>
        {footerImage && (
          <figure className={$.logoWrap}>
            <Image
              src={footerImage.url}
              loading="lazy"
              width="327"
              height="63"
              alt={footerImage.alt}
              className={$.logo}
            />
          </figure>
        )}
        <Grid>
          <GridItem xs={6} l={4}>
            {firstCategories.map((category) => (
              <FooterList {...category} key={category.id} />
            ))}
          </GridItem>
          <GridItem xs={6} l={4}>
            {secondCategories.map((category) => (
              <FooterList {...category} key={category.id} />
            ))}
          </GridItem>
          <GridItem xs={12} l={4}>
            <Grid>
              <GridItem xs={12} m={6} l={12}>
                {newsletter !== null && (
                  <section className={$.newsletter}>
                    <h3 className={$.title}>{t('FOOTER_NEWSLETTER')}</h3>
                    <p className={$.text}>{newsletter.text}</p>
                    <Button
                      type="link"
                      link={newsletter.link.url}
                      variant="red"
                      label={newsletter.link.name}
                      isExternal
                    />
                  </section>
                )}
              </GridItem>
              <GridItem xs={12} m={6} l={12}>
                <section className={$.socials}>
                  <h3 className={cx($.title, $.titleMarginBottom)}>
                    {t('FOOTER_SOCIALS')}
                  </h3>
                  {socials && (
                    <ul className={$.iconList}>
                      {socials.map((social) => (
                        <li className={$.iconListItem} key={social.id}>
                          <AjaxLink
                            link={social.link.url}
                            target={social.link.target}
                            title={social.link.name}
                            aria-label={social.link.name}
                            isExternal={social.link.target === '_blank'}
                          >
                            <Image
                              src={social.image.url}
                              alt={social.image.alt}
                              width="32"
                              height="32"
                              loading="lazy"
                              className={$.social}
                            />
                          </AjaxLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </section>
                <section className={$.partners}>
                  <h3 className={cx($.title, $.titleMarginBottom)}>
                    {t('FOOTER_PARTNERS')}
                  </h3>
                  {partnersSponsors.sponsors && (
                    <IconList partnersSponsors={partnersSponsors.sponsors} />
                  )}
                  {partnersSponsors.partners && (
                    <IconList partnersSponsors={partnersSponsors.partners} />
                  )}
                </section>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
        <p className={$.copyright}>{copyright}</p>
      </Container>
    </footer>
  );
}
