import type { DYProductData } from '~source/core/models/payload/dy-product-data';
import transformCartToDYFormat from '~source/core/transformers/payload/transform-cart-to-dy-format';

export default function createDYUpdateCartPayload(
  {
    price,
    currencyId,
    productId,
    size,
    shoppingCart,
    quantity: productQuantity,
  }: DYProductData,
  isRemoval = false,
) {
  const quantity = productQuantity || 1;
  const priceValue = price.sale || price.original;

  return {
    name: isRemoval ? 'Remove from Cart' : 'Add to Cart',
    properties: {
      dyType: isRemoval ? 'remove-from-cart-v1' : 'add-to-cart-v1',
      value: priceValue ? priceValue.toFixed(2) : null,
      currency: currencyId || 'EUR',
      productId,
      quantity,
      size: size ?? '',
      cart: shoppingCart ? transformCartToDYFormat(shoppingCart) : [],
    },
  };
}
